import React, { useState, useEffect, useContext } from 'react';
import Cards from '../../Components/Cards/Cards'
import LinePlot from '../../Components/Charts/LinePlotChart/LinePlot'
import DateTimeBox from '../../Components/DateTimeBox/DateTimeBox'
import Navbar from '../../Components/Navbar/Navbar'
import ChartTable from '../../Components/Table/ChartTable'
import { GET_VALUES1 } from "../../graphql/Query"
import { useQuery } from '@apollo/client';
import { FiAlignJustify } from "react-icons/fi"
import "./Dashboard.css"
import CountryContext from '../../Context/Country';


const dayjs = require('dayjs')

const Dashboard = ({ inputList, openLeftBar, setOpenLeftBar }) => {

    const [veri, setVeri] = useState([])

    const [dateStart, setDateStart] = useState(dayjs().format().slice(0, 10) + " " + "00:00:00+00")
    const [dateEnd, setDateEnd] = useState(dayjs().add(14, 'day').format().slice(0, 10) + " " + "00:00:00+00")
    const { countryValue, setCountryValue } = useContext(CountryContext)

    const [changeModel, setChangeModel] = useState("")
    const { loading, error, data } = useQuery(GET_VALUES1, {
        variables: {
            model: changeModel,
            startDate: dateStart,
            endDate: dateEnd,
            country: countryValue
        },
    })

   

    useEffect(() => {
        setVeri([])

        if (data) {
            setVeri(data.shortTermResultsWithRange)
        }
    }, [data, countryValue])
   
    useEffect(() => {
        setDateStart(dayjs().format().slice(0, 10) + " " + "00:00:00+00")
        setDateEnd(dayjs().add(14, 'day').format().slice(0, 10) + " " + "00:00:00+00")
    }, [countryValue])
    


    return (

        <div className='dashboard-container'>

            <div className='header'>
                <DateTimeBox setChangeModel={setChangeModel} setDateStart={setDateStart} setDateEnd={setDateEnd} veri={veri} />
            </div>
            <div className='charts-table-body'>
                <div className='table'>
                    <ChartTable veri={veri} setVeri={setVeri} setChangeModel={setChangeModel} />
                </div>
                <div className='charts'>
                    <LinePlot veri={veri} setVeri={setVeri} inputList={inputList} />
                </div>
            </div>

        </div>

    )
}

export default Dashboard