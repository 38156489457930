import React from "react"

const SuspenseContainer = ({ JsxElement }) => {
    return <React.Suspense
        fallback={<div>Loading...</div>}
    >
        {JsxElement}
    </React.Suspense>
}

export default SuspenseContainer;