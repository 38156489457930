import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [

  {
    title: 'Tahminler',
    path: '/tahminler/kısa-donem-tahminler',
    icon: <AiIcons.AiFillHome />,
    isOpen: false,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subMenu: [
      {
        title: '- Kısa Dönem',
        path: '/tahminler/kısa-donem-tahminler',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: '- Uzun Dönem',
        path: '/tahminler/uzun-donem-tahminler',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Raporlar',
    path: '/reports',
    icon: <IoIcons.IoIosPaper/>,
    isOpen: false,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subMenu: [
      {
        title: '- Kısa Dönem',
        path: '/reports',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: '- Uzun Dönem',
        path: '/reports-uzun-donem',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  // {
  //   title: 'Raporlar',
  //   path: '/reports',
  //   icon: <IoIcons.IoIosPaper />,

  // },
  {
    title: 'Çözümlerimiz',
    path: '/solutions',
    icon: <FaIcons.FaCartPlus />,
  },

  {
    title: 'Destek',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />,

  },
  // {
  //   title: 'Excel Upload',
  //   path: '/fileUpload',
    

  // },
];