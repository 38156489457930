import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import "./LoginPage.css"
import smartoptLogo from "../../assets/image/siyahLogo.png"

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className='loginPage'>
      <div className='cardLogin'>
        <div className='cardContent'>
          <img src={smartoptLogo} alt="bg" width="230"/>
          <button onClick={() => loginWithRedirect()}>Login</button>

        </div>
      </div>
      


    </div>
  )
}

export default LoginPage