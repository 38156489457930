import React, { useState, useEffect } from 'react';
import { Area, Line } from '@ant-design/plots';
import "./LinePlotLongTerm.css"


const LinePlotLongTerm = ({ veri, setVeri }) => {



  let maxValue = veri.map(item => {
    return item.predictedValue

  })
  const dataForLineChart = [
    ...veri.map((item) => ({ ...item, model: 'averagePreds', value: item.averagePreds, periods: new Date(item.period).toLocaleDateString() })),
    ...veri.map((item) => ({ ...item, model: 'lowerBound', value: item.lowerBound , periods: new Date(item.period).toLocaleDateString() })),
    ...veri.map((item) => ({ ...item, model: 'upperBound', value: item.upperBound , periods: new Date(item.period).toLocaleDateString() })),
  ];

  const commonConfig = {
    data: dataForLineChart,
    xField: 'periods',
    yField: 'value',
    seriesField: 'model',
    slider: {
      start: 0,
      end: 1,
      // trendCfg: {
      //   isArea: true,
      // },
    },
    point: {
      size: 3,
      shape: 'diamond',
    },
    xAxis: {
      tickCount: 12,
      grid: {
        line: {
          style: {
            stroke: 'black',
            lineWidth: 1,
            lineDash: [4, 5],
            strokeOpacity: 0.3,
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            cursor: 'pointer',
          },
        },
      },
    },
    yAxis: {
      // minLimit: Math.floor(Math.max(...maxValue) + 200),
      // maxLimit: Math.floor(Math.min(...maxValue) - 200),

      grid: {
        line: {
          style: {
            stroke: 'black',
            lineWidth: 1,
            strokeOpacity: 0.3,
            lineDash: [4, 5],
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            cursor: 'pointer',
          },
        },
      },
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };





  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error : {error.message}</p>;

  return (
    <>

      <div className='ChartsBody'>
        <Line {...commonConfig} />
      </div>
    </>
  )
}

export default LinePlotLongTerm