import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, gql,HttpLink,createHttpLink, concat } from '@apollo/client';
import { Auth0Provider } from "@auth0/auth0-react";
import { setContext } from '@apollo/client/link/context';


const httpLink = createHttpLink({ uri: 'https://smartoptcorspolicy.herokuapp.com/http://104.155.51.112/api' });
// const httpLink = createHttpLink({ uri: 'https://smartoptcorspolicy.herokuapp.com/http://sisecam-epf.eu-central-1.elasticbeanstalk.com/api' });
// const httpLink = createHttpLink({ uri: 'http://127.0.0.1:8000/api' });

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token") || null;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
      }
    }
  });


const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
        >
            <ApolloProvider client={client}>

                <App />

            </ApolloProvider>
        </Auth0Provider>
    </BrowserRouter>
);

