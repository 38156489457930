import { gql } from '@apollo/client';

export const GET_VALUES = gql`
    {
        shortTermResultsWithRange(startDate: "2023-02-24 20:00:00+00", endDate: "2023-03-01 21:00:00+00",model:"dnn"){
          id
          createdAt
          period
          model
          forecastPeriod
          predictedValue
          }
      }
  
  
`;

export const GET_VALUES1 = gql`
    query Query($model:String, $startDate:String, $endDate:String,$country:String){
        shortTermResultsWithRange(startDate: $startDate, endDate: $endDate,model:$model, country:$country){
          id
          createdAt
          period
          model
          forecastPeriod
          predictedValue
          }
      }
  
  
`;

export const GET_VALUES2 = gql`
    query Query( $date:String,$country:String){
        shortTermResultsWithSpecificDate(date: $date,model:"", country:$country){
          id
          createdAt
          period
          model
          forecastPeriod
          predictedValue
          }
      }
  
  
`;

export const GET_VALUES3 = gql`
    query Query( $date:String,$country:String){
        longTermResultsWithSpecificDate(date: $date, country:$country){
          id
          createdAt
          period
          lowerBound
          upperBound
          averagePreds
          }
      }
  
  
`;

export const GET_VALUES4 = gql`
    query Query( $date:String,$country:String){
        realizedDayAheadMcpWithSpecificDate(date: $date, country:$country){
          id
          date
          price
          priceEur
          priceUsd
          }
      }
  
  
`;

export const GET_VALUES5 = gql`
    query Query($country:String){
        lastLongTermResults(country:$country){
            id
            createdAt
            period
            lowerBound
            upperBound
            averagePreds
          }
      }
  
  
`;



// export const GET_VALUES2 = gql`
//    {
        
//         shortTermResultsWithSpecificDate(date:"2023-03-14 17:15:14.27757+00",model:"dnn"){
//             id
//             createdAt
//             period
//             model
//             forecastPeriod
//             }

//         }
    
  
// `;

