import React, { useState, useEffect } from 'react';
import { Area, Line } from '@ant-design/plots';
import "./LinePlotChartReport.css"


const LinePlotChartReport = ({ veri, veri1, setVeri }) => {

  let maxValue;
  let maxValue1;
  let mainData;
  if (veri1.length === 0) {
    mainData = veri.map(item => ({
      dataset: 'Tahmin',
      yValue: item.predictedValue,
      xValue: item.period,
    }));
  } else {
    mainData = veri1.map((item1) => {
      const matchInVeri = veri.find((item) => item.period === item1.date);

      if (matchInVeri) {
        return { ...item1, dataset: 'Gerçekleşen', yValue: item1.price, xValue: item1.date };
      } else {
        return { ...item1, dataset: 'Gerçekleşen', yValue: item1.price, xValue: item1.date, price: 0 };
      }
    });

    const unmatchedDatesInVeri = veri.filter((item) => veri1.filter((item1) => item1.date === item.period));
    const veriDataToAdd = unmatchedDatesInVeri.map((item) => ({ ...item, dataset: 'Tahmin', yValue: item.predictedValue, xValue: item.period }));
    mainData.push(...veriDataToAdd);

    mainData.sort((a, b) => new Date(a.xValue) - new Date(b.xValue));

    maxValue1 = veri1.map(item => {
      return item.price;
    });
    maxValue = veri.map(item => {
      return item.predictedValue;
    });
  }


  const config = {
    data: mainData,
    padding: 'auto',
    xField: 'xValue',
    yField: 'yValue',
    seriesField: "dataset",
    color: ({ dataset }) => {
      return dataset === 'Tahmin' ? '#6B7280' : '#f1b634';
    },
    xAxis: {
      tickCount: 10,
      grid: {
        line: {
          style: {
            stroke: 'black',
            lineWidth: 1,
            lineDash: [4, 5],
            strokeOpacity: 0.3,
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            cursor: 'pointer'
          }
        }
      }
    },
    slider: {
      start: 0,
      end: 1,
      trendCfg: {
        isArea: true,
      },
    },
    point: {
      size: 3,
      shape: 'diamond',
    },
    yAxis: {
      // minLimit: veri1.length === 0 ? undefined : Math.floor(Math.min(...maxValue1) - 350),
      minLimit: veri1.length === 0 ? undefined : Math.floor(Math.min(...maxValue1,...maxValue)) - 100,
      maxLimit: veri1.length === 0 ? undefined : Math.floor(Math.max(...maxValue1,...maxValue)) + 100,


      grid: {
        line: {
          style: {
            stroke: 'black',
            lineWidth: 1,
            strokeOpacity: 0.3,
            lineDash: [4, 5],
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            cursor: 'pointer'
          }
        }
      },
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },


  };



  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error : {error.message}</p>;

  return (
    <>

      <div className='ChartsBody'>
        <Line {...config} />
      </div>
    </>
  )
}

export default LinePlotChartReport