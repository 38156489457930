import React,{useState} from 'react'
import "./Support.css";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { Alert } from 'antd';
import baseUrl from "../../config";


export default function Support() {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [supportData,setSupportData] = useState({
        email:user.name,
        question:""
    })

    const [openAlert,setOpenAlert] =useState(false)

    const handleChangeSupport =(e) =>{
        setSupportData( {...supportData,[e.target.name]:e.target.value})

    }
    const handleSubmit = (e) =>{
        e.preventDefault()
        axios.post(`${baseUrl}/postSupport`,
        {user:user.email , email:supportData.email,question:supportData.question})
        .then(res => {
            if (res.status === 200) {
                setOpenAlert(true)
                setSupportData({})
            }
        });
    }
    return (
        <div className='supportBody'>
            <div className="container">
                <div className="content">
                    <div className="left-side">
                        <div className="address details">
                            <i className="fas fa-map-marker-alt"></i>
                            <div className="topic">Adres</div>
                            <div className="text-one">SmartOpt Teknoloji A.Ş.</div>
                            <div className="text-two">Kemerburgaz Mithatpaşa Mahallesi İstoza Mevkii Eyüpsultan / İstanbul</div>
                        </div>
                        <div className="phone details">
                            <i className="fas fa-phone-alt"></i>
                            <div className="topic">Telefon</div>
                            <div className="text-one">Mert Can ÖZCAN / +90537 836 3414</div>
                            <div className="text-two">Burak SARPKAYA / +90552 091 4493</div>
                        </div>
                        <div className="email details">
                            <i className="fas fa-envelope"></i>
                            <div className="topic">Email</div>
                            <div className="text-one">info@smartopt.com.tr</div>
                            <div className="text-two">mertcan.ozcan@smartopt.com.tr</div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="topic-text">Bizimle İletişime Geç</div>
                        {/* <p>If you have any work from me or any types of quries related to my tutorial, you can send me message from here. It's my pleasure to help you.</p> */}
                        <form onSubmit={handleSubmit}>
                            <div className="input-box">
                                <input style={{textTransform:"capitalize"}} onChange={handleChangeSupport} name='email' type="text" defaultValue={user.name} placeholder="Adınızı Giriniz" />
                            </div>
                            <div className="input-box1">
                                <textarea onChange={handleChangeSupport} name='question' rows="15" cols="33"  />
                            </div>
                            {/* <div className="input-box message-box">

                            </div> */}
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <button className="button" >Gönder</button>

                            </div>
                            {
                                openAlert ===true &&<div className='alert'>
                                <Alert message="Başarı ile gönderildi.." type="success" />
                            </div>
                            }


                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
