import React from 'react'
import "./DateTimeBoxLongTerm.css"
import { DatePicker, Button } from 'antd';
const dayjs = require('dayjs')


const DateTimeBoxLongTerm = ({ setChangeModel, setDateEnd, setDateStart, veri }) => {

    // const onChangeStart = (date, dateString) => {
    //     setDateStart(dateString + " " + "00:00:00+03");
    // };
    // const onChangeEnd = (date, dateString) => {
    //     setDateEnd(dateString + " " + "23:59:59+03");
    // };
    // const handleChange = (e) => {
    //     setChangeModel(e.target.value)
    // }

    // const disabledDate = (current) => {
    //     // Can not select days before today and today
    //     return current && current < dayjs().endOf('day');
    // };

    return (
        <div className='dateTimeBox'>
            <div className='dateTimeBoxDiv' >

                {/* <div className='dateLabel'>
                    <label>Başlangıç Tarihi : </label>
                    <DatePicker
                        className='datepicker'
                        defaultValue={dayjs()}
                        onChange={onChangeStart}
                        disabledDate={disabledDate}
                    />
                </div>
                <div className='dateLabel'>
                    <label>Bitiş Tarihi : </label>
                    <DatePicker
                        className='datepicker'

                        defaultValue={dayjs().add(14, 'day')}
                        disabledDate={disabledDate}
                        onChange={onChangeEnd} />
                </div>
                <div className='models'>
                    <label>Model : </label>
                    <select onChange={handleChange}>
                        <option value="">All</option>
                        <option value="lasso">Lasso</option>
                        <option value="dnn">DNN</option>
                    </select>
                </div> */}
            </div>

            <div className='createdAt'>
                <p>Son Güncelleme Tarihi : </p>
                <p>{veri[0]?.createdAt.slice(0, 16)}</p>
            </div>



        </div>
    )
}

export default DateTimeBoxLongTerm