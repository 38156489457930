import React, { useState, useEffect,useContext } from 'react';

import { GET_VALUES3, GET_VALUES5 } from "../../graphql/Query"
import { useQuery } from '@apollo/client';
import "./DashboardLongTerm.css"
import DateTimeBoxLongTerm from '../../Components/DateTimeBoxLongTerm/DateTimeBoxLongTerm';
import ChartTableLongTerm from '../../Components/Table/ChartTableLongTerm';
import LinePlotLongTerm from '../../Components/Charts/LinePlotLongTerm/LinePlotLongTerm';
import CountryContext from '../../Context/Country';


const dayjs = require('dayjs')

const DashboardLongTerm = ({ inputList, openLeftBar, setOpenLeftBar }) => {

    const [veri, setVeri] = useState([])

    const [dateStart, setDateStart] = useState(dayjs().format().slice(0, 10) + " " + "00:00:00+00")
    const [dateEnd, setDateEnd] = useState(dayjs().add(14, 'day').format().slice(0, 10) + " " + "00:00:00+00")
    const { countryValue, setCountryValue } = useContext(CountryContext)

    const [changeModel, setChangeModel] = useState("")
    const { loading, error, data } = useQuery(GET_VALUES5, {
        variables: {
          country:countryValue
        },
    })


    useEffect(() => {
        setVeri([])
        if (data) {
            setVeri(data.lastLongTermResults)
        }

    }, [data,countryValue])


    return (

        <div className='dashboard-container'>

            <div className='header'>
                <DateTimeBoxLongTerm setChangeModel={setChangeModel} setDateStart={setDateStart} setDateEnd={setDateEnd} veri={veri} />
            </div>
            <div className='charts-table-body'>
                <div className='table'>
                    <ChartTableLongTerm veri={veri} setVeri={setVeri} setChangeModel={setChangeModel} />
                </div>
                <div className='charts'>
                    <LinePlotLongTerm veri={veri} setVeri={setVeri} />
                </div>
            </div>
        
        </div>

    )
}

export default DashboardLongTerm