import React, { useContext } from 'react'
import "./Tabbar.css";
import { FiAlignJustify } from "react-icons/fi"
import * as IoIcons from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSolid, faUser } from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown, Tabs, ConfigProvider } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import CountryContext from '../../Context/Country';
import { Link } from 'react-router-dom';


export default function Tabbar({ setOpenLeftBar, openLeftBar }) {
    const { logout, user } = useAuth0();
    const { countryValue, setCountryValue } = useContext(CountryContext)


    const countryTabs = [
        {
            key: 'Turkey',
            label: 'Turkey',
            // children: 'Content of Tab Pane 1',
        },
        {
            key: 'Italy',
            label: 'Italy',
            // children: 'Content of Tab Pane 2',
        },

    ];
    const emailList = ["burak.sarpkaya@smartopt.com.tr","mertcan.ozcan@smartopt.com.tr","salih.gunduz@smartopt.com.tr","huseyin.saruhanlioglu@smartopt.com.tr", "anil.tasan@smartopt.com.tr"]
    const items = [
        {
            key: '1',
            label: (
                <p style={{ textTransform: "capitalize" }}>
                    Hoşgeldiniz {user.name}
                </p>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => logout()}>
                    Çıkış Yap
                </a>
            ),
        },

    ];
    const onChange = (key) => {
        setCountryValue(key)
    };

    // console.log(countryValue)


    return (
        <div className='tabbar-container'>
            <span className='tabbar-icon' onClick={() => setOpenLeftBar(!openLeftBar)}>
                {<FiAlignJustify size="30px" />}
            </span>

            <div className='switch'>
                {/* <span>Turkey</span> */}
                <ConfigProvider
                    theme={{
                        components: {
                            Tabs: {
                                colorPrimary: "#fff",
                                colorPrimaryHover: "#f1b634",
                                colorText: "#f1b634",
                                fontSize: "17px",
                            },
                        },
                    }}
                >
                    <Tabs
                        onChange={onChange}
                        items={countryTabs}

                    />
                </ConfigProvider>

                {/* <span>Italy</span> */}

            </div>
            {
                emailList.includes(user.email) ? <Link to="/fileUpload" style={{display:"flex", marginTop:"20px",color:"#fff", textDecoration:"none",width:"100px"}}>File Upload</Link> : null
            
            }
            <Dropdown
                menu={{
                    items,
                }}
                placement="bottomRight"
                arrow={{
                    pointAtCenter: true,
                }}
                trigger={['click']}
            >
                <Button className='logoutbutton'>
                    <FontAwesomeIcon icon={faUser} size="xl" style={{ color: "#f1b634", }} />
                </Button>
            </Dropdown>
        </div>
    )
}
