import React, { useState, useEffect } from 'react'
import { Table } from 'antd';
import "./ChartTableReportLong.css"
import * as XLSX from 'xlsx'
import { Excel } from "antd-table-saveas-excel";

import ExcelPng from "../../assets/image/excel.png"

const ChartTableReportLong = ({ veri, veri1 }) => {


    const [modifiedData, setModifiedData] = useState([]);


    useEffect(() => {

        // const dateMap = new Map();

        // veri1?.map(item => {
        //     dateMap.set(item.date, item.price)
        // })

        const dataList = veri?.map((item, index) => ({
            key: index + 1,
            period: item.period,
            averagePreds: Math.floor(item.averagePreds),
            lowerBound: Math.floor(item.lowerBound),
            upperBound: Math.floor(item.upperBound),

        }));

        setModifiedData([...dataList]);
    }, [veri]);

   
    const handleOnExport = () => {
        const excel = new Excel();
        excel
            .addSheet(`${new Date().toISOString().slice(0,10)}`)
            .addColumns(columns)
            .addDataSource(modifiedData, {
                str2Percent: true
            })
            .saveAs(`${new Date().toISOString().slice(0,10)}-Kısa Dönem Tahminler.xlsx`);
    };



    const columns = [
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            width: 100,
            render: (record, row) => {
                return row.period.slice(0, 10) + " " + row.period.slice(11, 16)
            }

        },
        {
            title: 'Ortalama',
            dataIndex: 'averagePreds',
            key: 'averagePreds',
            width: 90,
        },
        {
            title: 'Upper Bound',
            dataIndex: 'upperBound',
            key: 'upperBound',
            width: 90,
        },
        {
            title: 'Lower Bound',
            dataIndex: 'lowerBound',
            key: 'lowerBound',
            width: 90,
        },
        // {
        //     title: 'Gerçekleşmiş',
        //     dataIndex: 'price',
        //     key: 'price',
        //     width: 100,
        // },
        

    ];

    return (
        <div className='chartTable'>
            <div className='excel'>
                <img width={30} height={30} src={ExcelPng} alt="excel" onClick={handleOnExport} />
            </div>
            <Table
                dataSource={modifiedData}
                columns={columns}
                pagination={{ pageSize: 100000 }}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                sticky
            />

        </div>
    )
}

export default ChartTableReportLong