import React, { useState, useEffect, useContext } from 'react'
import "./DateTimeBox.css"
import { DatePicker, Button } from 'antd';
import Newspaper from "../../assets/image/newspaper.png"
import axios from 'axios';
import CountryContext from '../../Context/Country';

const dayjs = require('dayjs')


const DateTimeBox = ({ setChangeModel, setDateEnd, setDateStart, veri }) => {

    const [newsLink, setNewsLink] = useState("")
    const { countryValue, setCountryValue } = useContext(CountryContext)


    const onChangeStart = (date, dateString) => {
        setDateStart(dateString + " " + "00:00:00+03");
    };
    const onChangeEnd = (date, dateString) => {
        setDateEnd(dateString + " " + "23:59:59+03");
    };
    const handleChange = (e) => {
        setChangeModel(e.target.value)
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    
    useEffect(() => {
        // axios.post(`https://api.epias.com.tr/epias/exchange/transparency/bulletin/daily?date=${new Date().toISOString().slice(0, 10)}`)
        // axios.get("https://smartoptcorspolicy.herokuapp.com/http://sisecam-epf.eu-central-1.elasticbeanstalk.com/returnLinkToEPIASDailyBulletin")
        axios.get("https://smartoptcorspolicy.herokuapp.com/http://104.155.51.112/returnLinkToEPIASDailyBulletin")

            .then(response => setNewsLink(response.data))
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        setDateStart(dayjs().format().slice(0, 10) + " " + "00:00:00+00")
        setDateEnd(dayjs().add(14, 'day').format().slice(0, 10) + " " + "00:00:00+00")
    }, [countryValue])




    return (
        <div className='dateTimeBox'>
            <div className='dateTimeBoxDiv' >

                <div className='dateLabel'>
                    <label>Başlangıç Tarihi : </label>
                    <DatePicker
                        className='datepicker'
                        defaultValue={dayjs()}
                        onChange={onChangeStart}
                        disabledDate={disabledDate}
                    />
                </div>
                <div className='dateLabel'>
                    <label>Bitiş Tarihi : </label>
                    <DatePicker
                        className='datepicker'

                        defaultValue={dayjs().add(14, 'day')}
                        disabledDate={disabledDate}
                        onChange={onChangeEnd} />
                </div>
                <div className='models'>
                    <label>Model : </label>
                    <select onChange={handleChange}>
                        <option value="">All</option>
                        <option value="lasso">Lasso</option>
                        <option value="dnn">DNN</option>
                    </select>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: "-15px" }}>
                {
                    countryValue === "Turkey" ?
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <p >EPİAŞ Günlük Bülten</p>
                            <a href={newsLink} target='_blank' >
                                <img style={{ marginLeft: "65px", marginBottom: "10px" }} src={Newspaper} alt="" width={30} height={30} />
                            </a>
                        </div>
                        : null
                }

                <div className='createdAt' style={{ marginTop: "-15px" }}>
                    <p>Son Güncelleme Tarihi : </p>
                    <p>{veri[0]?.createdAt.slice(0, 16)}</p>
                </div>
            </div>



        </div>
    )
}

export default DateTimeBox