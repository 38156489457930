import React, { useState, useEffect,useContext } from 'react'
import { Table } from 'antd';
import "./ChartTableReport.css"
import * as XLSX from 'xlsx'
import { Excel } from "antd-table-saveas-excel";

import ExcelPng from "../../assets/image/excel.png"
import CountryContext from '../../Context/Country';

const ChartTableReport = ({ veri, veri1 }) => {


    const [modifiedData, setModifiedData] = useState([]);
    const { countryValue, setCountryValue } = useContext(CountryContext)


    useEffect(() => {

        const dateMap = new Map();

        veri1?.map(item => {
            dateMap.set(item.date, item.price)
        })

        const dataList = veri?.map((item, index) => ({
            key: index + 1,
            period: item.period,
            //   model: item?.model,
            predictedValue: item?.predictedValue,
            price: dateMap.get(item.period) || "",
            sapmaOranı: (() => {
                const price = dateMap.get(item.period);
                const predictedValue = item.predictedValue || 0;
    
                if (price && predictedValue) {
                    return Math.abs(((predictedValue - price) / price) * 100).toFixed(2);
                } else {
                    return "";
                }
            })(),
            // sapmaOranı: dateMap.get(item.period) ? Math.abs((((item?.predictedValue -( dateMap.get(item.period) ? dateMap.get(item.period) : 0)) / dateMap.get(item.period)) *100).toFixed(2)) : ""

        }));

        setModifiedData([...dataList]);
    }, [veri,veri1,countryValue]);

   
    const handleOnExport = () => {
        const excel = new Excel();
        excel
            .addSheet(`${new Date().toISOString().slice(0,10)}`)
            .addColumns(columns)
            .addDataSource(modifiedData, {
                str2Percent: true
            })
            .saveAs(`${new Date().toISOString().slice(0,10)}-Kısa Dönem Tahminler.xlsx`);
    };



    const columns = [
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            width: 90,
            render: (record, row) => {
                return row.period.slice(0, 10) + " " + row.period.slice(11, 16)
            }

        },
        {
            title: 'Tahmin',
            dataIndex: 'predictedValue',
            key: 'predictedValue',
            width: 80,
        },
        {
            title: 'Gerçekleşmiş',
            dataIndex: 'price',
            key: 'price',
            width: 112,
        },
        {
            title: 'Sapma Oranı (%)',
            dataIndex: 'sapmaOranı',
            key: 'sapmaOranı',
            width: 130,
        },
        

    ];

    return (
        <div className='chartTable'>
            <div className='excel'>
                <img width={30} height={30} src={ExcelPng} alt="excel" onClick={handleOnExport} />
            </div>
            <Table
                dataSource={modifiedData}
                columns={columns}
                pagination={{ pageSize: 100000 }}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                sticky
            />

        </div>
    )
}

export default ChartTableReport