import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import FileUpload from './Pages/FileUpload/FileUpload';
import { useState, useEffect } from 'react';
import Navbar from './Components/Navbar/Navbar';
import LoginPage from './Pages/LoginPage/LoginPage';
import { useAuth0 } from "@auth0/auth0-react";
import SuspenseContainer from "./Components/SuspenseContainer"
import axios from 'axios';
import Dashboard from './Pages/Dashboard/Dashboard';
import HomeLayout from "./Layouts/HomeLayout/HomeLayout";
import Reports from './Pages/Reports/Reports';
import Solutions from './Pages/Solutions/Solutions';
import Support from './Pages/Supports/Support';
import Maintenance from './Components/Maintenance/Maintenance';
import DashboardLongTerm from './Pages/DashboardLongTerm/DashboardLongTerm';
import ReportsUzunDonem from './Pages/ReportsUzunDonem/ReportsUzunDonem';
import CountryContext from './Context/Country';
function App() {

  const [inputList, setInputList] = useState([])
  const [openLeftBar, setOpenLeftBar] = useState(false);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [countryValue, setCountryValue] = useState(localStorage.getItem("country") ? localStorage.getItem("country") : "Turkey");;

  const countryContextData = {
    countryValue,
    setCountryValue
  }
  useEffect(() => {
    localStorage.setItem("country", countryValue)
  }, [countryValue])

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     getIdTokenClaims().then((claims) => {
  //       // Kullanıcının rollerini burada alabilirsiniz
  //       const roles = claims["https://sisecam-forecast.eu.auth0.com/roles"];
  //       console.log("Kullanıcı rolleri:", roles);
  //     });
  //   }
  // }, [isAuthenticated, getIdTokenClaims]);


  // var options = {
  //   method: 'POST',
  //   url: 'https://sisecam-forecast.eu.auth0.com/oauth/token',
  //   headers: {'content-type': 'application/x-www-form-urlencoded'},
  //   data: new URLSearchParams({
  //     grant_type: 'client_credentials',
  //     client_id: 'tuDrDSCXwvEDOmFeASAJZnVnHoXVlk79',
  //     client_secret: '10WIv0Dar8U2CQwaydZbe62YVaOz3XfVky5CWx0Lz6W0O00JKAe-2FuqFZtxdJf3',
  //     audience: 'https://sisecam-drf-auth/'
  //   })
  // };

  // var settings = {
  //   "async": true,
  //   "crossDomain": true,
  //   "url": "https://sisecam-forecast.eu.auth0.com/oauth/token",
  //   "method": "POST",
  //   "headers": {
  //     "content-type": "application/json"
  //   },
  //   "data": "{\"client_id\":\"A9P0NXmam8eRZd2xZCptI1rsJCMekKQU\",\"client_secret\":\"QK7-xHARnV6w_0XoAjsDBD3v7Zy2r5y_wfcZ8BBXVPlqEk1LN0a410_DRs06qZwU\",\"audience\":\"https://sisecam-drf-auth/\",\"grant_type\":\"client_credentials\"}"

  // }

  // axios.request(settings).then(function (response) {
  //   localStorage.setItem("token", response.data.access_token);
  // }).catch(function (error) {
  //   console.error(error);
  // });


  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (!isAuthenticated) {
    return (

      <>
        <Routes>
          <Route path='/' element={<SuspenseContainer JsxElement={<LoginPage inputList={inputList} openLeftBar={openLeftBar} setOpenLeftBar={setOpenLeftBar} />} />} />
        </Routes>
      </>
    );
  }

  if (isAuthenticated) {
    return (
      <div className='app'>
        <CountryContext.Provider value={countryContextData}>
          <Navbar openLeftBar={openLeftBar} setOpenLeftBar={setOpenLeftBar} />
          <HomeLayout setOpenLeftBar={setOpenLeftBar} openLeftBar={openLeftBar}>
            <Routes>
              <Route path="/" element={<Navigate to="/tahminler/kısa-donem-tahminler" replace />} />
              <Route path='/tahminler/kısa-donem-tahminler' element={<Dashboard />} />
              <Route path="/tahminler/uzun-donem-tahminler" element={<DashboardLongTerm />} />
              <Route path='/reports' element={<SuspenseContainer JsxElement={<Reports inputList={inputList} />} />} />
              <Route path='/reports-uzun-donem' element={<SuspenseContainer JsxElement={<ReportsUzunDonem inputList={inputList} />} />} />
              <Route path='/solutions' element={<SuspenseContainer JsxElement={<Solutions inputList={inputList} />} />} />
              <Route path='/support' element={<SuspenseContainer JsxElement={<Support inputList={inputList} />} />} />
              <Route path='/fileUpload' element={<SuspenseContainer JsxElement={<FileUpload setInputList={setInputList} inputList={inputList} />} />} />
              <Route path="*" element={<Navigate to="/tahminler/kısa-donem-tahminler" replace />} />
            </Routes>
          </HomeLayout>
        </CountryContext.Provider>
      </div>
    );
  }
}

export default App;
