import React, { useState, useEffect } from 'react';
import { Area, Line } from '@ant-design/plots';
import "./LinePlot.css"
import * as XLSX from 'xlsx'
import axios from 'axios';
import { useQuery } from '@apollo/client';
import { GET_VALUES } from "../../../graphql/Query"
import { GET_VALUES1 } from "../../../graphql/Query"

const LinePlot = ({veri, setVeri}) => {

    // const { loading, error, data } = useQuery(GET_VALUES1)

    
    // const handleOnExport = () => {
    //     var wb = XLSX.utils.book_new(),
    //         ws = XLSX.utils.json_to_sheet(data);
    //     XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
    //     XLSX.writeFile(wb, "MyExcel.xlsx")
    // }

    let maxValue = veri.map(item =>{ 
      return item.predictedValue
       
    })

    const config = {
        data:veri,
        padding: 'auto',
        xField: 'period',
        yField: 'predictedValue',
        seriesField:"model",
        xAxis: {
            tickCount: 10,
            grid: {
                line: {
                  style: {
                    stroke: 'black',
                    lineWidth: 1,
                    lineDash: [4, 5],
                    strokeOpacity: 0.3,
                    shadowColor: 'black',
                    shadowBlur: 10,
                    shadowOffsetX: 5,
                    shadowOffsetY: 5,
                    cursor: 'pointer'
                  }
                }
              }
        },
        slider: {
            start: 0,
            end: 1,
            trendCfg: {
                isArea: true,
            },
        },
        point: {
            size: 3,
            shape: 'diamond',
        },
        yAxis: {
            minLimit: Math.floor(Math.max(...maxValue)+200),
            maxLimit: Math.floor(Math.min(...maxValue)-200),
            
            grid: {
                line: {
                  style: {
                    stroke: 'black',
                    lineWidth: 1,
                    strokeOpacity: 0.3,
                    lineDash: [4, 5],
                    shadowColor: 'black',
                    shadowBlur: 10,
                    shadowOffsetX: 5,
                    shadowOffsetY: 5,
                    cursor: 'pointer'
                  }
                }
              },
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
              },
        },
        

    };

    

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error : {error.message}</p>;

    return (
        <>

            <div className='ChartsBody'>
                <Line {...config} />
            </div>
        </>
    )
}

export default LinePlot