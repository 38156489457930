import React, { useState } from 'react'
import * as XLSX from 'xlsx'
import "./FileUpload.css"
import axios from 'axios'
import baseUrl from "../../config";

const FileUpload = ({ inputList }) => {


  const [data, setData] = useState([])
  const [data1, setData1] = useState([])

  const [refreshPage, setRefreshPage] = useState(false)
  const [loading, setLoading] = useState(false)


  const handleFile = async (e) => {
    const file = e.target.files[0];

    // setFileName(file.name)
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellDates: true,
      cellNF: true,
      cellText: false,
      dateNF: 'm/d/yy h:mm',
      blankCell: false,

    });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: "yyyy-mm-dd hh:mm:ss" })
    setData(jsonData)


  }

  const handleFile1 = async (e) => {
    const file = e.target.files[0];

    // setFileName(file.name)
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellDates: true,
      cellNF: true,
      cellText: false,
      dateNF: 'm/d/yy h:mm',
      blankCell: false,

    });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: "yyyy-mm-dd hh:mm:ss" })
    setData1(jsonData)


  }

  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`
  //   }
  // }


  const SubmitFileData = (country) => {
    setLoading(true)
    axios.post(

      `${baseUrl}/addShortTermResultExcel`, { data,country })
      .then(res => {
        if (res.status === 200) {
          alert("Veri Yüklendi")
          setLoading(false)

          setRefreshPage(!refreshPage);

        }
      })
  }

  const SubmitFileData1 = (country) => {
    setLoading(true)

    axios.post(

      `${baseUrl}/addLongTermResultExcel`, { data1,country })
      .then(res => {
        if (res.status === 200) {
          alert("Veri Yüklendi")
          setLoading(false)

          setRefreshPage(!refreshPage);

        }
      })
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "25px", margin: "auto" }}>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "40px" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
          <h3 style={{ marginBottom: "-20px" }}>Short Term Excel Dosyası YÜKLE-TÜRKİYE</h3>
          <div className='fileUpload'>
            <input type="file" onChange={(e) => handleFile(e)} />
            <button component="label" onClick={() => SubmitFileData("Turkey")} disabled={data.length === 0} >
              Yükle
            </button>
          </div>

        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
          <h3 style={{ marginBottom: "-20px" }}>Long Term Excel Dosyası YÜKLE-TÜRKİYE</h3>

          <div className='fileUpload'>
            <input type="file" onChange={(e) => handleFile1(e)} />
            <button component="label" onClick={() => SubmitFileData1("Turkey")} disabled={data1.length === 0} >
              Yükle
            </button>
          </div>

        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "40px" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
          <h3 style={{ marginBottom: "-20px" }}>Short Term Excel Dosyası YÜKLE-İTALYA</h3>
          <div className='fileUpload'>
            <input type="file" onChange={(e) => handleFile(e)} />
            <button component="label" onClick={() => SubmitFileData("Italy")} disabled={data.length === 0} >
              Yükle
            </button>
          </div>

        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
          <h3 style={{ marginBottom: "-20px" }}>Long Term Excel Dosyası YÜKLE-İTALYA</h3>

          <div className='fileUpload'>
            <input type="file" onChange={(e) => handleFile1(e)} />
            <button component="label" onClick={() => SubmitFileData1("Italy")} disabled={data1.length === 0} >
              Yükle
            </button>
          </div>

        </div>



      </div>
    </div>

  )
}

export default FileUpload