import React from 'react'
import Tabbar from '../../Components/Tabbar/Tabbar'
import "./HomeLayout.css";

function HomeLayout({ children, setOpenLeftBar, openLeftBar }) {
    return (
        <div className='home-layout-container'>
            <Tabbar setOpenLeftBar={setOpenLeftBar} openLeftBar={openLeftBar} />
            {children}
        </div>
    )
}

export default HomeLayout