import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import smartLogo from "../../assets/image/smartoptLogo.png"

import { useAuth0 } from "@auth0/auth0-react";

function Navbar({ setOpenLeftBar, openLeftBar }) {
  const { logout, user } = useAuth0();
  const [sideBarData, setSideBarData] = useState([...SidebarData])


  // const showSidebar = () => setSidebar(!sidebar);


  const handleChange = (item, index) => {
    let newArray = [...sideBarData]
    if (item.subMenu) {
      sideBarData[index].isOpen = !sideBarData[index].isOpen
      setSideBarData(newArray)
    }
  }
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar' style={{ display: openLeftBar && "flex" }}>

          <div>
            <img src={smartLogo} alt="logo" width="140px" height="30px" />
            <span className='close-icon' onClick={() => setOpenLeftBar(false)} >X</span>
          </div>

          <div className='sideItem'>
            {sideBarData.map((item, index) => {
              return (
                <div key={index} style={{ display: "flex", flexDirection: "column" }}>

                  <li
                    key={index}
                    className="nav-text"
                    onClick={() => handleChange(item, index)}
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive && index !== 0 ? '#fff' : null,
                      // background: isActive && index !== 0 ? '#f1b634' : null,
                    })} to={item.path}>

                      {item.icon}
                      <span>{item.title}</span>
                      {item.subMenu
                        ? <span> {item.isOpen ? item.iconOpened : item.iconClosed}</span>
                        : null
                      }
                    </NavLink>
                  </li>
                  
                  {item.isOpen === true
                    ? <div
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      {item.subMenu.map((subItem, index) => {
                        return <li key={index} className="nav-text-sub" >
                          <NavLink to={subItem.path} style={({ isActive }) => ({
                            color: isActive ? '#fff' : null,
                            background: isActive ? '#f1b634' : null,
                          })}>{subItem.title}</NavLink>
                        </li>
                      })}

                    </div>
                    : null}
                </div>
              );
            })}
          </div>

          <div className='copyRight'>
            &copy;2023 SmartOpt.
          </div>

        </div>


      </IconContext.Provider>

    </>

  );
}

export default Navbar;
